import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Prestige Classes`}</h2>
    <p>{`Prestige classes offer a new form of multiclassing. Unlike the basic classes, characters must meet Requirements before they can take their first level of a prestige class. The rules for level advancement apply to this system, meaning the first step of advancement is always choosing a class. If a character does not meet the Requirements for a prestige class before that first step, that character cannot take the first level of that prestige class. Taking a prestige class does not incur the experience point penalties normally associated with multiclassing.
There are 15 prestige classes defined in the SRD. They are:`}</p>
    <ol>
      <li parentName="ol">{`Arcane Archer`}</li>
      <li parentName="ol">{`Arcane Trickster`}</li>
      <li parentName="ol">{`Archmage`}</li>
      <li parentName="ol">{`Assassin`}</li>
      <li parentName="ol">{`Blackguard`}</li>
      <li parentName="ol">{`Dragon Disciple`}</li>
      <li parentName="ol">{`Duelist`}</li>
      <li parentName="ol">{`Dwarven Defender`}</li>
      <li parentName="ol">{`Eldritch Knight`}</li>
      <li parentName="ol">{`Hierophant`}</li>
      <li parentName="ol">{`Horizon Walker`}</li>
      <li parentName="ol">{`Loremaster`}</li>
      <li parentName="ol">{`Mystic Theurge`}</li>
      <li parentName="ol">{`Shadowdancer`}</li>
      <li parentName="ol">{`Thaumaturgist`}</li>
    </ol>
    <h6>{`Definitions of Terms`}</h6>
    <p>{`Here are definitions of some terms used in this section.`}</p>
    <p><strong parentName="p">{`Base Class`}</strong>{`: One of the standard eleven classes.`}</p>
    <p><strong parentName="p">{`Caster Level`}</strong>{`: Generally equal to the number of class levels (see below) in a spellcasting class. Some prestige classes add caster levels to an existing class.`}</p>
    <p><strong parentName="p">{`Character Level`}</strong>{`: The total level of the character, which is the sum of all class levels held by that character.`}</p>
    <p><strong parentName="p">{`Class Level`}</strong>{`: The level of a character in a particular class. For a character with levels in only one class, class level and character level are the same.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      